<template>
  <header class="bg-white fixed inset-x-0 top-0 z-50">
    <div class="container flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 py-2.5 lg:py-4">
      <NuxtLink to="/" class="inline-block">
        <img src="~/assets/images/logo.svg" alt="" class="w-[136px] sm:w-40 md:w-44 lg:w-[206px]" />
      </NuxtLink>
      <div v-if="isPayeeVerificationRoute === true">
        <img src="~/assets/images/sa-logo.png" alt="" class="w-[136px] sm:w-40 md:w-44 lg:w-[206px]" />
      </div>
      <div v-else>
        <ul class="flex items-center space-x-10">
          <li class="font-trajan text-xl pt-1">ABOVE ALL, LOVE</li>
          <li class="w-[120px] flex-col" v-if="!loading && languageOptions.length > 1">
            <div
              class="block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500 text-sm overflow-hidden w-[120px]"
              for="input_0"
            >
              <div class="w-[200px] animate-slide-left-right"><span class="mr-24">Idioma</span><span>Language</span></div>
            </div>
            <FormKit
              type="select"
              :options="languageOptions"
              outer-class="!mb-0"
              label-class="text-sm animate-slide-left-right"
              input-class="!py-1.5"
              @change="switchLanguage"
              :value="locale"
            />
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { inject, ref } from 'vue';
import { getAvailableLanguages } from '~/composables/queries/languages';
import { useLanguageStore } from '~/composables/stores/languages';
import { useUserStore } from '~/composables/stores/user';

const config = inject(Symbol.for('FormKitConfig'));

const { locale, setLocale } = useI18n();
config.locale = locale.value;
console.log(config.locale);
watch(locale, (newVal) => {
  config.locale = newVal;
});

const modalOpen = ref(false);
function toggleModal() {
  modalOpen.value = !modalOpen.value;
}

const currentRoute = useRoute();

const isPayeeVerificationRoute = computed(() => {
  const payeeVerificationRoute = /^\/payee\/.*\/verification$/;
  return payeeVerificationRoute.test(currentRoute.path);
});

const userStore = useUserStore();
setInterval(() => {
  userStore.fetchUser();
}, 1000 * 10);

const switchLanguage = (e: Event) => {
  const target = e.target as HTMLSelectElement;
  const selectedLanguage = target.value;
  setLocale(selectedLanguage);
};

const listOfLanguages = ref<{ value: string; label: string; id: string }[]>([]);

const languageStore = useLanguageStore();
const serviceAreaStore = useServiceAreaStore();
const { languageOptions } = storeToRefs(languageStore);

const { onResult, loading } = getAvailableLanguages();
onResult((d) => {
  if (d.data?.tbl_languages.length > 0) {
    for (let i = 0; i < d.data.tbl_languages.length; i++) {
      let lang = d.data.tbl_languages[i];
      if (
        serviceAreaStore.chosen?.id === '' ||
        serviceAreaStore.chosen?.tbl_service_areas_languages?.filter((l) => l.language_id === lang.id && l.enabled).length > 0
      ) {
        listOfLanguages.value.push({
          value: lang.language_code,
          label: lang.language_name_native,
          id: lang.id,
        });
      }
    }

    languageStore.setAvailableLanguages(listOfLanguages.value);
    languageStore.setLanguageOptions(listOfLanguages.value);

    if (d.data.tbl_languages.filter((lang: { language_code: string }) => lang.language_code === locale.value).length === 0) {
      setLocale('en');
    }
  } else {
    setLocale('en');
  }
});
</script>
