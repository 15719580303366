export const getAvailableLanguages = () => {
  const query = gql`
    query getLanguages {
      tbl_languages(where: { active: { _eq: true } }) {
        id
        language_code
        language_name
        language_name_native
      }
    }
  `;

  return useQuery(query);
};
